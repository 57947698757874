// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Vectorsvg2IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Vectorsvg2Icon(props: Vectorsvg2IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 12 10"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M2.853 2.406c-.851.795-1.457 1.74-1.78 2.311a.58.58 0 000 .565c.323.572.929 1.517 1.78 2.312C3.706 8.391 4.763 9 6 9c1.237 0 2.294-.61 3.146-1.406.852-.795 1.458-1.74 1.78-2.311a.579.579 0 000-.565c-.322-.572-.928-1.517-1.78-2.312C8.294 1.609 7.237 1 6 1c-1.237 0-2.294.61-3.147 1.406zm-.682-.731C3.127.782 4.416 0 6 0s2.873.782 3.829 1.675 1.62 1.935 1.968 2.552c.27.478.27 1.067 0 1.546-.347.617-1.012 1.66-1.968 2.552C8.873 9.218 7.584 10 5.999 10c-1.583 0-2.872-.782-3.828-1.675S.55 6.391.202 5.774m0-1.547a1.58 1.58 0 000 1.547V4.227z"
        }
        fill={"currentColor"}
      ></path>

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M9.146 2.406c.852.795 1.458 1.74 1.78 2.312a.578.578 0 010 .565c-.322.572-.928 1.516-1.78 2.311C8.294 8.391 7.237 9 6 9c-1.237 0-2.294-.61-3.147-1.406-.851-.795-1.457-1.74-1.78-2.312a.58.58 0 010-.565c.323-.572.929-1.516 1.78-2.311C3.706 1.609 4.763 1 6 1c1.237 0 2.294.61 3.146 1.406zm.683-.731C8.873.782 7.584 0 5.999 0 4.417 0 3.128.782 2.172 1.675S.55 3.61.202 4.227a1.58 1.58 0 000 1.547c.348.617 1.013 1.658 1.969 2.551C3.127 9.218 4.416 10 6 10s2.873-.782 3.829-1.675 1.621-1.935 1.969-2.552m0 0c.27-.479.27-1.068 0-1.546v1.546z"
        }
        fill={"currentColor"}
      ></path>

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M6 3.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM3.5 5a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vectorsvg2Icon;
/* prettier-ignore-end */
